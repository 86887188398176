import React from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import ReactHtmlParser from "react-html-parser";
import styles from "./styles";
import Img from "gatsby-image";
import { transformLink } from "../../../helpers/";

import ProductOverviewIcons from "../ProductOverviewIcons";
import { CgbAccordionBlock } from "../../blocks/CgbAccordionBlock";
import { openOverlay } from "../../../app/actions";

const mapDispatchToProps = (dispatch) => ({
  openOverlay: (settings) => dispatch(openOverlay(settings)),
});

const connector = connect(null, mapDispatchToProps);

const useStyles = makeStyles((theme) => styles(theme));

function ProductOverview(props) {
  // console.log(props);
  const classes = useStyles();

  let overviewSplit = ["", ""];

  if (props.overview !== null) {
    overviewSplit = props.overview.split("<p>{icons}</p>");
  }

  let faqs = [];

  if (props.faqs !== null) {
    props.faqs.forEach((faq) => {
      faqs.push({
        attributes: {
          className: "",
          open: false,
        },
        innerBlocks: [
          {
            attributes: {
              align: "",
              anchor: "",
              backgroundColor: "",
              className: "",
              content: faq.question,
              level: 3,
              textColor: "",
              __typename: "WpCoreHeadingBlockAttributes",
            },
            innerBlocks: [],
            name: "core/heading",
            __typename: "WpCoreHeadingBlock",
          },
          {
            attributes: {
              align: "",
              backgroundColor: "",
              className: "",
              content: faq.answer,
              direction: "",
              textColor: "",
              __typename: "WpCoreParagraphBlockAttributes",
            },
            innerBlocks: [],
            name: "core/paragraph",
            __typename: "WpCoreParagraphBlock",
          },
        ],
        name: "cgb/accordion-inner",
        __typename: "WpCgbAccordionInnerBlock",
      });
    });
  }

  const [activeTab, setActiveTab] = React.useState("overview");

  const openOverlay = () => {
    const settings = {
      buyOnline: props.buyOnline,
      buyOffline: props.buyOffline,
    };
    props.openOverlay(settings);
  };

  let ctaButton = null;
  if (props.buyOffline || props.buyOnline) {
    ctaButton = (
      <button className="stockists" onClick={() => openOverlay()}>
        Find stockist
      </button>
    );
  } else {
    ctaButton = (
      <Link className="stockists" to="/about-us/contact-us/">
        Contact us
      </Link>
    );
  }

  return (
    <div
      className={`product-overview  ${classes.productOverview}  ${classes.productOverviewTheme}`}
    >
      <div className="overview-nav">
        <div className="wrapper">
          <button
            onClick={() => setActiveTab("overview")}
            onKeyDown={() => setActiveTab("overview")}
            data-active={"overview" === activeTab ? true : false}
          >
            Overview
          </button>
          {props.faqs !== null && (
            <button
              onClick={() => setActiveTab("faq")}
              onKeyDown={() => setActiveTab("faq")}
              data-active={"faq" === activeTab ? true : false}
            >
              FAQs
            </button>
          )}
          {props.specBlurb != "" && (
            <button
              onClick={() => setActiveTab("specifiers")}
              onKeyDown={() => setActiveTab("specifiers")}
              data-active={"specifiers" === activeTab ? true : false}
            >
              Additional Information
            </button>
          )}
        </div>
      </div>
      {activeTab === "overview" && (
        <div className="overview">
          <h2>Overview</h2>
          <div className="overview-columns">
            <div class="content">
              {props.overview !== null &&
                ReactHtmlParser(overviewSplit[0], { transform: transformLink })}
              {props.icons !== null && (
                <ProductOverviewIcons icons={props.icons} />
              )}
              {props.overview !== null &&
                ReactHtmlParser(overviewSplit[1], { transform: transformLink })}
              <div className="actions">
                {ctaButton}
                {props.brochure !== null && (
                  <a
                    href={props.brochure.localFile.publicURL}
                    target="_blank"
                    className="brochure"
                  >
                    Brochure
                  </a>
                )}
              </div>
              {props.datasheet !== null && props.instruction !== null && (
                <div className="usefull-links">
                  <h4>Helpful files</h4>
                  <div class="links">
                    {props.datasheet !== null && (
                      <a
                        href={props.datasheet.localFile.publicURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Datasheet
                      </a>
                    )}
                    {props.instruction !== null && (
                      <a
                        href={props.instruction.localFile.publicURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instructions
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="features">
              {props.gallery && (
                <div className="gallery">
                  <Img fluid={props.gallery.localFile.childImageSharp.fluid} />
                </div>
              )}

              {props.features !== null && (
                <div className="features-list">
                  <h3>Features</h3>
                  <ul>
                    {props.features.map((feature) => {
                      return <li>{ReactHtmlParser(feature.feature)}</li>;
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {props.faqs !== null && activeTab === "faq" && (
        <div className="product-faqs">
          <h2>Frequently asked questions</h2>
          <CgbAccordionBlock
            attributes={{
              className: "",
            }}
            innerBlocks={faqs}
          />
        </div>
      )}
      {props.specBlurb != "" && activeTab === "specifiers" && (
        <div className="specifiers">
          <div className="content-col">
            <h2>For Specifiers</h2>
            {ReactHtmlParser(props.specBlurb)}

            <div className="actions">
              <Link to="/about-us/contact-us/">Talk to our team</Link>
            </div>

            <div className="usefull-links">
              <h4>Helpful files</h4>
              <div class="links">
                {props.datasheet !== null && (
                  <a
                    href={props.datasheet.localFile.publicURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Datasheet
                  </a>
                )}
                {props.instruction !== null && (
                  <a
                    href={props.instruction.localFile.publicURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instructions
                  </a>
                )}
                {props.bmi !== null && (
                  <a
                    href={props.bmi.localFile.publicURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    BMI file
                  </a>
                )}
                <Link to={props.specificationBrochure}>
                  Download Specification Brochure
                </Link>
              </div>
            </div>
          </div>
          <div className="gallery">
            {props.specVideo && (
              <div className="video-wrapper">
                <iframe
                  title={
                    "video-banner-" +
                    props.specVideo.replace(
                      "https://www.youtube.com/watch?v=",
                      ""
                    )
                  }
                  src={
                    "https://www.youtube.com/embed/" +
                    props.specVideo.replace(
                      "https://www.youtube.com/watch?v=",
                      ""
                    )
                  }
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            )}
            {!props.specVideo && props.specVideoFallback && (
              <div className="video-fallback">
                <Img
                  fluid={
                    props.specVideoFallback.localFile.childImageSharp.fluid
                  }
                />
              </div>
            )}
            <div className="gallery-images">
              {props.specGallery && (
                <Img
                  fluid={props.specGallery.localFile.childImageSharp.fluid}
                />
              )}
              {props.specGallery2 && (
                <Img
                  fluid={props.specGallery2.localFile.childImageSharp.fluid}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default connector(ProductOverview);
